import React, { FC, useMemo } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { BsCodeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";

import Matomo from "./Matomo";

import { useLocation } from "react-router";
import { queryURLToNavState } from "../lib/navState";

const Footer: FC = () => {
  const location = useLocation();
  const rawNavState = useMemo(() => queryURLToNavState(location.search), [location.search]);
  const url = useMemo(() => rawNavState.url, [rawNavState]);
  const cortextAttribution = {
    sentenceText: 'Hosted by the',
    linkText: 'Cortext Platform',
    textLink: 'https://www.cortext.net/',
    logoLink: 'https://docs.cortext.net/'
  }

  if (url && url.includes('cortext')) {
    cortextAttribution.sentenceText = 'Network made with';
    cortextAttribution.linkText = 'Cortext Manager';
    cortextAttribution.textLink = 'https://docs.cortext.net/';
    cortextAttribution.logoLink = 'https://www.cortext.net/';
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <Link to="/" className="flex-shrink-0 me-2 p-0">
          <img
            src={process.env.PUBLIC_URL + "/logo.svg"}
            alt="Retina logo"
            style={{ height: "1.2em" }}
            className="me-1"
          />
        </Link>
        <div className="flex-grow-1">
          <Link to="/">Retina</Link> is built with <AiOutlineHeart /> by{" "}
          <a href="https://www.ouestware.com/en" target="_blank" rel="noreferrer">
            OuestWare
          </a>
          ,{" "}
          <a href="https://cis.cnrs.fr" className="text-nowrap" target="_blank" rel="noreferrer">
            CNRS CIS
          </a>{" "}
          and{" "}
          <a href="http://www.tommasoventurini.it/" className="text-nowrap" target="_blank" rel="noreferrer">
            Tommaso Venturini
          </a>
        </div>
        <div className="flex-shrink-0 ms-2">
          <a href="https://gitlab.com/ouestware/retina" target="_blank" rel="noreferrer">
            <BsCodeSlash />
          </a>
        </div>
      </div>
      <hr className="m-0 flex-shrink-0" />
      <div className="d-flex flex-row align-items-center justify-content-center">
        <div className="flex-shrink-0 me-2">
          {cortextAttribution.sentenceText}{" "}
          <a href={cortextAttribution.textLink} target="_blank" rel="noreferrer">
            {cortextAttribution.linkText}
          </a>
        </div>
        <div className="flex-shrink-0 p-0">
          <a href={cortextAttribution.logoLink} target="_blank" rel="noreferrer">
            <img
              src={process.env.PUBLIC_URL + "/logo_cortext.png"}
              alt="Cortext logo"
              style={{ height: "1.2em" }}
              className="me-1"
            />
          </a>
        </div>
      </div>
      <Matomo />
    </>
  );
}

export default Footer;
